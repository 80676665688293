import React from "react"
import Layout from "../components/layout"

const NotFoundPage = ({data}) => {
  const site = data.site.siteMetadata;
  return (
    <Layout
      title={site.title}
      description={site.description}
      url={site.url}
    >
      <div className='container' style={{height: '80vh'}}>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query notFoundQuery {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`
